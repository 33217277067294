<template>
    <main>
      <div class="wait">
		  <div class="wait-content">
              <div width="100%" align="center">Calcul des résultats ...</div>
              <br />
              <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
              <br />
              <br />
              <br />
              <br />              
              <br />
              <br />   
              <br />  
              <p class="wait-slogan">Un bon vin se sert avec tout<br />avec modération surtout.</p>                        
          </div>          
                 
      </div> 
	  <div id="slide" class="slide">
	  </div>
    </main>
</template>
<script>
export default {
	name: 'confirm',
	data: function () {
		return {
			width: document.documentElement.clientWidth,
			slide: 1,
			slideloop: undefined
		};
    },
 	beforeRouteLeave(to, from, next) {
		clearInterval(this.slideloop);
	  next()
	},   
	mounted() {
        fbq('track', 'CompleteRegistration');
        this.slidebg()
        setTimeout(() => {  
          this.clearlopp() 
          this.$router.push("/resultats");
        }, 5000);
	},
	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},
	methods: {
		myEventHandler(e) {
			this.width = e.currentTarget.innerWidth
		},
		clearlopp() {
			clearInterval(this.slideloop);
			this.slide = 1;
		},
		slidebg: function () {
			this.slideloop = window.setInterval(() => {
				this.slide++
				if (this.slide > 7) this.slide = 1

				if (this.width > 900) {
					slide.style.backgroundImage = "url('/homescreens/screen" + this.slide + ".jpg')";
				} else {
					slide.style.backgroundImage = "url('/homescreens/screen" + this.slide + "-mobile.jpg')";
				}
			}, 600);
		}
	}
}
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";

.slide {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url('/homescreens/screen1.jpg');
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  z-index: 1;

  @media screen and (max-width: $mobile-width) {
    background-image: url('/homescreens/screen1-mobile.jpg');
    background-size: auto 100%;
    background-position: top center;
  }
}

.wait {
    position: fixed;
    background-color: rgba(0, 0, 0, .6);
    width:100%;
    height:100vh;
    z-index:99;
    display:flex;
    justify-content: center;
    align-items: flex-start;
}

.wait-content {
    color:white;
    margin-top:200px;
    font-family: $font-extra;
    font-weight: bold;
    font-style: italic;
    font-size:40px;  
    line-height: 40px; 
    @media screen and (max-height: 720px) {
        margin-top: 90px;
    }
}

.wait-slogan {
    width:100%;
    color:white;
    font-family: $font-extra;
    font-weight: bold;
    font-style: italic;
    font-size:40px;  
    line-height: 40px; 
    text-align: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin:0 auto;
  width: 280px;
  height: 280px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;

}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>